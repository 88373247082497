.header-divider {
    margin-left: 0vw;
    margin-right: 0vw;
  }

  .App-header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 90;
  }

  .header {
    height: 7vh;
  }