.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

.main-content {
  max-width: 100%;
  height: 100%;
}

.Navigator-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Keeps content at the top */
  height: 100vh; /* Full height of the viewport */
  position: fixed;
  width: 15vw;
  padding-left: 2em;
  z-index: 20; /* Ensures it stays above other content */
}

.Content-col {
  display: flex;

  /* position: fixed; */
  /* Keeps content at the center */

  height: 100vh; /* Full height of the viewport */
  width: 100vw;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 10vw;

  /* padding-left:  */
  z-index: 10; /* Ensures it stays above other content */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.w-ninety {
  width: 90%;
}
